<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span v-if="user[1] == 'AGEROUTE'">Décomptes ></span>
        <span v-else>Factures ></span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div v-if="user[1] != 'DGIR'" 
               class="card-body text-center title">
            Liste des décomptes
          </div>
          <div v-else 
               class="card-body text-center title">
            Liste des factures
          </div>
        </div>
      </div>
    </div>
    <div v-if="user[1] == 'AGEROUTE'"
         class="table_action">
      <div class="div_left">
        <router-link :to="{ path: '/decompte/prestation' }"
                     class="btn btn-blue mr-3">
          Ajouter un décompte presta. intellect.
        </router-link>
        <router-link :to="{ path: '/decompte/ageroute/travaux' }"
                     class="btn btn-blue">
          Ajouter un décompte travaux
        </router-link>
      </div>
    </div>
    <div v-else
         class="table_action">
      <div class="div_left">
        <!-- <router-link :to="{ path: '/decompte/prestation' }"
                     class="btn_round">
          Ajouter une facture presta. intellect.
        </router-link> -->
        <router-link :to="{ path: '/decompte/dgir/travaux' }"
                     class="btn btn-blue">
          Ajouter une facture travaux
        </router-link>
      </div>
      <!-- <div class="div_right">
        <button class="btn btn-blue"
                @click="downloadExport(decomptes.url)">
          Exporter
        </button>
      </div> -->
    </div>
    
    <ul v-if="user[1] != 'DGIR'"
        class="nav nav-tabs"
        id="myTab"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="home-tab"
           data-toggle="tab"
           href="#home"
           role="tab"
           aria-controls="home"
           aria-selected="true">Liste des décomptes complets</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link"
           id="profile-tab"
           data-toggle="tab"
           href="#profile"
           role="tab"
           aria-controls="profile"
           aria-selected="false">Liste des décomptes partiels</a>
      </li> -->
    </ul>
    <ul v-else
        class="nav nav-tabs"
        id="myTab"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="home-tab"
           data-toggle="tab"
           href="#home"
           role="tab"
           aria-controls="home"
           aria-selected="true">Liste des factures complètes</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link"
           id="profile-tab"
           data-toggle="tab"
           href="#profile"
           role="tab"
           aria-controls="profile"
           aria-selected="false">Liste des factures partielles</a>
      </li> -->
    </ul>
    <div v-if="user[1] == 'AGEROUTE'"
         class="tab-content"
         id="myTabContent">
      <div class="tab-pane fade show active"
           id="home"
           role="tabpanel"
           aria-labelledby="home-tab">
        
        <div class="row my-3">
          <div class="div_left">
            <button class="btn btn-blue pl-5 pr-5 op-btn mr-5"
                    data-toggle="modal"
                    ref="modal_button"
                    data-target="#filtreModal">Filtrer</button>
          </div>
          <!-- <div class="div_left">
            <button class="btn btn-blue"
                    @click="downloadExport(decomptes.url)">
              Exporter
            </button>
          </div> -->
        </div>
        <!-- <div class="div_right"></div> -->

        <div class="inner_container">
          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped"
                   style="width:4750px">
              <thead>
                <tr>
                  <th scope="col"
                      style="width:140px"
                      class="text-left th-blue">
                    Type de Décompte
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="text-left">
                    Statut
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="text-left th-blue">
                    Numéro Décompte
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Programme
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Marché
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Activité
                  </th>
                  <!-- <th scope="col"
                        style="width:140px"
                      class="th-blue text-left">
                    Lot
                  </th>-->
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Taux d'exécution physique
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="text-left th-blue">
                    Référence Ordre de service de prorogation de délai
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date de service de prorogation de délai 
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="text-left th-blue">
                    Référence Ordre de service de modification d'itinéraire
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date de l'Ordre de service de modification d'itinéraire 
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement de l'assurance chantier
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="text-left th-blue">
                    Date d'expiration de l'assurance chantier
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement du cautionnement AD
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date Expiration du cautionnement AD
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement du cautionnement RG
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date Expiration du cautionnement RG
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement du procès verbal de reception des travaux
                  </th>
                  <!-- <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement de la feuille de temps
                  </th> -->
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement du décompte
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement de la facture
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date de l'Attachement
                  </th>
                  <th scope="col"
                      style="width:140px"
                      class="th-blue text-left">
                    Date d'établissement du certificat de service fait
                  </th>

                  <!-- <th scope="col"
                      style="width:140px"
                      class="text-left">Dernière décision</th> -->
                  <!-- <th scope="col"
                      style="width:140px"
                      class="text-left">Dernier valideur</th> -->
                  <th scope="col"
                      style="width:140px"
                      class="text-left">Statut du paiement</th>
                  <th scope="col"
                      style="width:140px"
                      class="text-right">Montant de la facture(FCFA)</th> 
                  <th scope="col"
                      style="width:140px"
                      class="text-right">Montant du décompte(FCFA)</th>


                </tr>
              </thead>
              <tbody v-if="!loaded">
                <tr>
                  <td colspan="26"
                      class="text-center">
                    Chargement ...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="long != 0">
                <tr class="text-uppercase" 
                    v-for="(decompte, key) in decomptes.donnees"
                    :key="key"
                    @click="launchDetailsAgeroute(decompte.id)">
                  <td class="text-left">{{ decompte.type_decompte }}</td>
                  <td v-if="decompte.statut_final == 'VALIDE'"
                      class="text-left">
                    <span class="badge badge-pill badge-success">
                      {{ decompte.statut_final }}
                    </span>
                  </td>
                  <td v-else-if="decompte.statut_final == 'EN_ATTENTE'"
                      class="text-left">
                    <span class="badge badge-pill badge-warning">
                      {{ decompte.statut_final }}
                    </span>
                  </td>
                  <td v-else>Inconu</td>
                  <td class="text-left">{{ decompte.numero_decompte }}</td>
                  <td class="text-left">{{ decompte.programme.nom_programme }}</td>

                  <td class="text-left">{{ decompte.marche.numero_marche }}</td>
                  <td class="text-left">{{ decompte.activite.libelle }}</td>
                  <td class="text-left">{{ decompte.taux_execution_physique }}</td> 

                  <td class="text-left">{{ decompte.reference_ordre_service_prorogation_delai }}</td>
                  <td class="text-left">{{ decompte.date_ordre_service_prorogation_delai }}</td>
                  <td class="text-left">{{ decompte.reference_ordre_service_modification_itineraire }}</td>
                  <td class="text-left">{{ decompte.date_service_modification_itineraire }}</td>
                  <td class="text-left">{{ decompte.date_assurance_chantier }}</td>
                  <td class="text-left">{{ decompte.expiration_assurance_chantier_date }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_cautionnement_ad }}</td>
                  <td class="text-left">{{ decompte.expiration_caution_ad_texte == null || decompte.expiration_caution_ad_texte == "" 
                    ?decompte.expiration_caution_ad_date :decompte.expiration_caution_ad_texte }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_cautionnement_rg }}</td>
                  <td class="text-left">{{ decompte.expiration_caution_rg_texte == null || decompte.expiration_caution_rg_texte == "" 
                    ?decompte.expiration_caution_rg_date :decompte.expiration_caution_rg_texte }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_proces_verbal_reception_travaux }}</td>

                  <!-- <td class="text-left">{{ decompte.date_etablissement_feuille_temps }}</td> -->
                  <td class="text-left">{{ decompte.date_facture }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_decompte }}</td>
                  <td class="text-left">{{ decompte.date_attachement_constat_travaux }}</td>

                  <td class="text-left">{{ decompte.date_certificat_service_fait }}</td>
                
                  <!-- <td v-if="(decompte.action_dernier_valideur=='EN ATTENTE' && dernier_valideur[key]== null) 
                        || (dernier_valideur[key]== '')"
                      class="text-left">{{ decompte.action_dernier_valideur }}</td>
                  <td v-else-if="decompte.action_dernier_valideur=='MODIFIER'"
                      class="text-uppercase text-left">
                    MODIFIE</td>
                  <td v-else-if="(decompte.action_dernier_valideur=='VALIDE' && (dernier_valideur[key] !=null && dernier_valideur[key][4]== 4) )"
                      class="text-left">
                    VALIDE</td>
                  <td v-else-if="(decompte.action_dernier_valideur=='VALIDE' && (dernier_valideur[key] !=null && dernier_valideur[key][4]== 3) )"
                      class="text-left">
                    APPROUVE</td>
                  <td v-else-if="(decompte.action_dernier_valideur=='VALIDE' && (dernier_valideur[key] !=null && dernier_valideur[key][4]== 2) )"
                      class="text-left">
                    VERIFIE</td>
                  <td v-else-if="(decompte.action_dernier_valideur=='VALIDE' && (dernier_valideur[key] !=null && dernier_valideur[key][4]== 1) )"
                      class="text-left">
                    TRAITE</td>
                  <td v-else
                      class="text-left">AUCUNE ACTION</td> -->
                  <!-- <td class="text-left">{{ decompte.action_dernier_valideur }}</td> -->
                  <!-- <td class="text-left">{{ getProfileDernierValideur(decompte.dernier_valideur) }}</td> -->
                  <td class="text-left">{{ decompte.statut_paiement }}</td>
                  <td class="text-right">{{ Math.round(decompte.montant_decompte).toLocaleString() }}</td>
                  <td class="text-right">{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }}</td>
                
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="26"
                      class="text-center">Aucun decompte trouvé!</td>
                </tr>
              </tbody>
            </table>
            <div class="row pagination">
              <div v-if="decomptes.pagination"
                   class="col-md-1 mx-auto text-center">
                <span v-if="decomptes.pagination.precedent"
                      class="badge badge-pill badge-info"
                      @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
                {{ pIndex }}
                <span v-if="decomptes.pagination.suivant"
                      class="badge badge-pill badge-info"
                      @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DECOMPTE PARTIEL AGEROUTE -->
      <div class="tab-pane fade"
           id="profile"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <div class="div_left">
          <button class="btn btn-blue pl-5 pr-5 op-btn"
                  data-toggle="modal"
                  ref="modal_button"
                  data-target="#filtreModal">Filtrer</button>
        </div>
        <!-- <div class="div_left">
          <a class="btn btn-blue "
             :href="baseUrl + decomptesP.url"
             download>
            Exporter
          </a>
        </div> -->
        <!-- <div class="div_right"></div> -->

        <div class="inner_container">
          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped">
              <thead>
                <tr>
                  <th scope="col"
                      class="text-left">
                    Numéro Décompte
                  </th>
                  <th scope="col">
                    Programme
                  </th>
                  <th scope="col">
                    Marché
                  </th>
                  <th scope="col">
                    Activité
                  </th>
                </tr>
              </thead>
              <tbody v-if="!loadedP">
                <tr>
                  <td class="text-center"
                      colspan="10">
                    Chargement...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="longP != 0">
                <tr v-for="(decompte, dkey) in decomptesP.donnees"
                    :key="dkey"
                    @click="launchModifPAgeroute(decompte.id, 'partiel')">
                  <td>
                    {{ decompte.numero_decompte }}
                  </td>
                  <td>{{ decompte.programme.libelle }}</td>
                  <td>{{ decompte.marche.numero_marche }}</td>
                  <td>{{ decompte.activite.libelle }}</td>
                  <!-- <td>{{ decompte.sous_traitant.libelle }}</td> -->
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center"
                      colspan="10">
                    Aucun décompte partiel enregistré
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row pagination">
              <div v-if="decomptesP.pagination"
                   class="col-md-1 mx-auto text-center">
                <span v-if="decomptesP.pagination.precedent"
                      class="badge badge-pill badge-info"
                      @click="pIndex--, paging(decomptesP.pagination.precedent)"> &#60; </span>
                {{ pIndex }}
                <span v-if="decomptesP.pagination.suivant"
                      class="badge badge-pill badge-info"
                      @click="pIndex++, paging(decomptesP.pagination.suivant)"> &#62; </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin affichage AGEROUTE -->
    <div v-else
         class="tab-content"
         id="myTabContent">
      <div class="tab-pane fade show active"
           id="home"
           role="tabpanel"
           aria-labelledby="home-tab">
        <div class="inner_container">
          <div class="row my-3">
            <div class="div_left">
              <button class="btn btn-blue pl-5 pr-5 op-btn mr-5"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#filtreModal">Filtrer</button>
            </div>
            <!-- <div class="div_left">
              <button class="btn btn-blue"
                      @click="downloadExport(decomptes.url)">
                Exporter
              </button>
            </div> -->
          </div>
          <!-- <div class="div_right"></div> -->

          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped"
                   style="width:7000px" >
              <thead>
                <tr>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Type de Décompte
                  </th>
                  <th scope="col"
                      class="text-left"
                      style="width:140px;">
                    Statut
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Numéro Décompte
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Programme
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Marché
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Activité
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence Ordre de service de prorogation de délai
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de service de prorogation de délai 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence Ordre de service de modification d'itinéraire
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de l'Ordre de service de modification d'itinéraire 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence de la lettre de commande
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de la lettre de commande 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence du courrier d'appel de fonds
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du courrier d'appel de fonds 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence du courrier d'indication de la ligne lcd
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du courrier d'indication de la ligne lcd 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence du courrier d'invitation SN
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du courrier d'invitation SN 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence de la demande de proposition de prix
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de la demande de proposition de prix 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence du courrier de confirmation de prix
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du courrier de confirmation de prix 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence de la demande de cotation
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de la demande de cotation 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence du formulaire de sélection
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du formulaire de sélection 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence du certificat de paiement
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du certificat de paiement 
                  </th>
                  <th scope="col"
                      class="text-left th-blue"
                      style="width:140px;">
                    Référence de l'ordre de mission
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de l'ordre de mission 
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Référence du certificat de service fait
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du certificat de service fait
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Taux d'execution physique 
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de la feuille de temps 
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date d'établissement de la facture 
                  </th>

                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date d'établissement du décompte 
                  </th>

                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du bon de livraison 
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du certificat de participation 
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date du procès verbal de reception des travaux 
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:140px;">
                    Date de l'attachement 
                  </th>

                  <th scope="col"
                      class="th-blue text-left"
                      style="width:180px;">
                    Date d'établissement du cautionnement d'avance de demarrage
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:180px;">
                    Date d'expiration du cautionnement d'avance de demarrage
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:180px;">
                    Date d'établissement du cautionnement de la retenue garantie
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:160px;">
                    Date d'expiration du cautionnement de la retenue garantie
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:160px;">
                    Date d'établissement du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:160px;">
                    Date d'expiration du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:160px;">
                    Date d'établissement du certificat de service fait
                  </th>
                  <th scope="col"
                      class="th-blue text-left"
                      style="width:160px;">
                    Date d'expiration du certificat de service fait
                  </th>

                  <!-- <th scope="col"
                      class="text-left"
                      style="width:140px;">Dernière décision</th> -->
                  <!-- <th scope="col"
                      class="text-left"
                      style="width:140px;">Dernier valideur</th> -->
                  <th scope="col"
                      class="text-left"
                      style="width:140px;">Statut du paiement</th>
                  <th scope="col"
                      class="text-right"
                      style="width:140px;">Montant de la facture(FCFA)</th>
                  <th scope="col"
                      class="text-right"
                      style="width:140px;">Montant du décompte(FCFA)</th>


                </tr>
              </thead>
              <tbody v-if="!loaded">
                <tr>
                  <td colspan="21"
                      class="text-center">
                    Chargement ...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="loaded && long != 0">
                <tr v-for="(decompte, key) in decomptes.donnees"
                    :key="key"
                    @click="launchDetailDgir(decompte.id)"
                    class="text-uppercase">
                  <td class="text-left">{{ decompte.type_decompte }}</td>
                  <td class="text-left"
                      v-if="decompte.statut_final === 'VALIDE'">
                    <span class="badge badge-pill badge-success">
                      {{ decompte.statut_final }}
                    </span>
                  </td>
                  <td class="text-left"
                      v-if="decompte.statut_final === 'EN ATTENTE'">
                    <span class="badge badge-pill badge-warning">
                      {{ decompte.statut_final }}
                    </span>
                  </td>
                  <td class="text-left"
                      v-else>
                    <span class="badge badge-pill badge-warning">
                      {{ decompte.statut_final }}
                    </span>
                  </td>
                  <td class="text-left">{{ decompte.numero_decompte }}</td>
                  <td class="text-left">{{ decompte.programme.nom_programme }}</td>
                  <td class="text-left">{{ decompte.marche.numero_marche }}</td>
                  <td class="text-left">{{ decompte.activite.libelle }}</td>
                  <td class="text-left">{{ decompte.reference_ordre_service_prorogation_delai }}</td>
                  <td class="text-left">{{ decompte.date_ordre_service_prorogation_delai }}</td>
                  <td class="text-left">{{ decompte.reference_ordre_service_modification_itineraire }}</td>
                  <td class="text-left">{{ decompte.date_ordre_service_modification_itineraire }}</td>
                  <td class="text-left">{{ decompte.reference_lettre_commande }}</td>
                  <td class="text-left">{{ decompte.date_lettre_commande }}</td>
                  <td class="text-left">{{ decompte.reference_courrier_appel_fond }}</td>
                  <td class="text-left">{{ decompte.date_courrier_appel_fond }}</td>
                  <td class="text-left">{{ decompte.reference_courrier_indication_ligne_lcd }}</td>
                  <td class="text-left">{{ decompte.date_courrier_indication_ligne_lcd }}</td>
                  <td class="text-left">{{ decompte.reference_courrier_invitation_sn }}</td>
                  <td class="text-left">{{ decompte.date_courrier_invitation_sn }}</td>
                  <td class="text-left">{{ decompte.reference_demande_proposition_prix }}</td>
                  <td class="text-left">{{ decompte.date_demande_proposition_prix }}</td>
                  <td class="text-left">{{ decompte.reference_courrier_confirmation_prix }}</td>
                  <td class="text-left">{{ decompte.date_courrier_confirmation_prix }}</td>
                  <td class="text-left">{{ decompte.reference_demande_cotation }}</td>
                  <td class="text-left">{{ decompte.date_demande_cotation }}</td>
                  <td class="text-left">{{ decompte.reference_formulaire_selection }}</td>
                  <td class="text-left">{{ decompte.date_formulaire_selection }}</td>
                  <td class="text-left">{{ decompte.reference_certificat_paiement }}</td>
                  <td class="text-left">{{ decompte.date_certificat_paiement }}</td>
                  <td class="text-left">{{ decompte.reference_ordre_mission }}</td>
                  <td class="text-left">{{ decompte.date_ordre_mission }}</td>
                  <td class="text-left">{{ decompte.reference_certificat_service_fait }}</td>
                  <td class="text-left">{{ decompte.date_certificat_service_fait }}</td>
                  <td class="text-left">{{ decompte.taux_execution_decompte }}</td>
                  <td class="text-left">{{ decompte.date_feuille_temps }}</td>
                  <td class="text-left">{{ decompte.date_decompte }}</td>
                  <td class="text-left">{{ decompte.date_facture }}</td>
                  <td class="text-left">{{ decompte.date_bon_livraison }}</td>
                  <td class="text-left">{{ decompte.date_certificat_participation }}</td>
                  <td class="text-left">{{ decompte.date_proces_verbal_reception_travaux }}</td>
                  <td class="text-left">{{ decompte.date_attachement }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_cautionnement_ad }}</td>
                  <td class="text-left">{{ decompte.expiration_caution_ad_date==null?decompte.expiration_caution_ad_date:decompte.expiration_caution_ad_text }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_cautionnement_rg }}</td>
                  <td class="text-left">{{ decompte.expiration_caution_rg_date==null?decompte.expiration_caution_rg_date:decompte.expiration_caution_rg_text }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_cautionnement_definitif }}</td>
                  <td class="text-left">{{ decompte.expiration_caution_definitif_date==null?decompte.expiration_caution_definitif_date:decompte.expiration_caution_definitif_text }}</td>
                  <td class="text-left">{{ decompte.date_etablissement_certificat_service_fait }}</td>
                  <td class="text-left">
                    {{ decompte.expiration_certificat_service_fait_date==null?decompte.expiration_certificat_service_fait_date:decompte.expiration_certificat_service_fait_text }}
                  </td>
                  <!-- <td v-if="decompte.action_dernier_valideur == 'MODIFIER'"
                      class="text-uppercase text-left">modifié</td>
                  <td v-else
                      class="text-uppercase text-left">{{ decompte.action_dernier_valideur }}</td> -->

                  <td class="text-left">{{ decompte.statut_paiement }}</td>
                  <!-- <td>{{ decompte.action_dernier_valideur }}</td> -->
                  <td class="text-right">{{ Math.round(decompte.montant_decompte).toLocaleString() }}</td>
                  <td class="text-right">{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }}</td>

                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center"
                      colspan="20">
                    Aucune facture enregistrée
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row pagination">
              <div v-if="decomptes.pagination"
                   class="col-md-1 mx-auto text-center">
                <span v-if="decomptes.pagination.precedent"
                      class="badge badge-pill badge-info"
                      @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
                {{ pIndex }}
                <span v-if="decomptes.pagination.suivant"
                      class="badge badge-pill badge-info"
                      @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DECOMPTE PARTIEL DGIR -->
      <div class="tab-pane fade"
           id="profile"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <div class="inner_container">
          <div class="div_left">
            <button class="btn btn-blue pl-5 pr-5 op-btn"
                    data-toggle="modal"
                    ref="modal_button"
                    data-target="#filtreModal">Filtrer</button>
          </div>
          <!-- <div class="div_left">
            <a class="btn btn-blue"
               :href="baseUrl + decomptesP.url"
               download>
              Exporter
            </a>
          </div> -->
          <!-- <div class="div_right"></div> -->

          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped">
              <thead>
                <tr>
                  <th scope="col"
                      class="text-left">
                    Numéro facture
                  </th>
                  <th scope="col">
                    Programme
                  </th>
                  <th scope="col">
                    Lettre de commande
                  </th>
                  <th scope="col">
                    Activité
                  </th>
                </tr>
              </thead>
              <tbody v-if="!loadedP">
                <tr>
                  <td class="text-center"
                      colspan="10">
                    Chargement...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="longP != 0">
                <tr v-for="(decompte, dkey) in decomptesP.donnees"
                    :key="dkey"
                    @click="launchModifPDgir(decompte.id, 'partiel')">
                  <td>
                    {{ decompte.numero_decompte }}
                  </td>
                  <td>{{ decompte.programme.libelle }}</td>
                  <td>{{ decompte.marche.numero_marche }}</td>
                  <td>{{ decompte.activite.libelle }}</td>
                  <!-- <td>{{ decompte.sous_traitant.libelle }}</td> -->
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center"
                      colspan="10">
                    Aucun décompte enregistré
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row pagination">
              <div v-if="decomptes.pagination"
                   class="col-md-1 mx-auto text-center">
                <span v-if="decomptes.pagination.precedent"
                      class="badge badge-pill badge-info"
                      @click="pIndex--, paging(decomptes.pagination.precedent)"> &#60; </span>
                {{ pIndex }}
                <span v-if="decomptes.pagination.suivant"
                      class="badge badge-pill badge-info"
                      @click="pIndex++, paging(decomptes.pagination.suivant)"> &#62; </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- fin partie DGIR -->
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer(type)">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./decompte.css";
table.table.tablestyle_3.table-striped{
  width: 155%;
}
li.nav-item{
  font-size: 0.7em;
}
</style>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import utils from "@/assets/js/utils"
import ListFilter from "@/components/shared/Filter"

export default {
  name: "Decompte",
  components:{
    ListFilter,
  },
  data: () => ({
    decompteData: {
      libelle: "",
      order: "",
      tri: ""
    },
    filtre:[],
    chmp:[
      {
        valeur:"numero_decompte",
        type:"caractere",
        table:"decomptes",
        libelle:"Numéro décompte",
      },
      {
        valeur:"nom_programme",
        type:"caractere",
        table:"programmes",
        libelle:"Programme",
      },
      {
        valeur:"numero_marche",
        type:"caractere",
        table:"marches",
        libelle:"Marché",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"activites",
        libelle:"Activité",
      },
      {
        valeur:"objet",
        type:"caractere",
        table:"decomptes",
        libelle:"Objet des travaux",
      },
      {
        valeur:"type_entretien",
        type:"caractere",
        table:"decomptes",
        libelle:"Type d'entretien",
      },
      {
        valeur:"date_facture",
        type:"date",
        table:"decomptes",
        libelle:"Date de transmission",
      },
      {
        valeur:"date_approbation",
        type:"date",
        table:"decomptes",
        libelle:"Date d'approbation",
      },
      {
        valeur:"date_debut_execution",
        type:"date",
        table:"decomptes",
        libelle:"Date de début d'exécution",
      },
      {
        valeur:"date_demarrage_effectif",
        type:"date",
        table:"decomptes",
        libelle:"Date de démarrage effectif",
      },
      {
        valeur:"date_fin_execution",
        type:"date",
        table:"decomptes",
        libelle:"Date de fin d'exécution",
      },
      {
        valeur:"date_ordre_service",
        type:"date",
        table:"decomptes",
        libelle:"Date d'ordre de service",
      },
      {
        valeur:"date_ordonnancement",
        type:"date",
        table:"decomptes",
        libelle:"Date d'ordonnancement",
      },
      {
        valeur:"montant_ordonnancement",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant d'ordonnancement",
      },
      {
        valeur:"montant_facture_decompte",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant",
      },
    ],
    baseUrl: process.env.VUE_APP_UPLOAD,
    pIndex: 1,
    long: "",
    longP: "",
    loaded: false,
    loadedP: false,
    user: ""
  }),
  watch: {
    decomptes() {
      if (this.decomptes.donnees) {
        this.loaded = true
        this.long = this.decomptes.donnees.length
        this.$refs["btn-close"].click()
        // this.decomptes.donnees.forEach(decompte => {
        //   if (decompte.statut_final !== "VALIDE") {
        //     decompte.statut_final = decompte.statut_final.split("_")
        //     decompte.statut_final = decompte.statut_final[0] + " " + decompte.statut_final[1]
        //     if (decompte.action_dernier_valideur !== "VALIDE") {
        //       decompte.action_dernier_valideur=decompte.action_dernier_valideur.replace("_"," ")
        //     }
        //   }
        // })
      }
    },
    decomptesP() {
      this.loadedP = true
      this.longP = this.decomptesP.donnees.length
      this.$refs["btn-close"].click()

    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.getDecomptes()
    this.DecomptesP()
  },
  computed: {
    ...mapGetters(["decomptes", "decomptesP", "decompterrors", "decompterrorsP"])
  },
  methods: {
    ...mapActions(["getDecomptes", "DecomptesP", "pageDecomptes"]),
    ...mapMutations(["setDecomptes",]),
    onFormChange() {
      this.form.validate()
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      this.loaded = false
      this.long = null
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.setDecomptes("")
      this.pageDecomptes(url)
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.getDecomptes(this.filtre)
    },
    restoring() {
      this.loaded = false
      this.long = null
      this.getDecomptes()
      this.decompteData = { libelle: "", order: "", tri: "" }
    },
    launchDetailsAgeroute(id) {
      this.$router.push({ path: "/decompte/ageroute/detail/" + id })
    },
    launchModifPAgeroute(id, p) {
      this.$router.push({ path: "/decompte/" + p + "/ageroute/modification/" + id })
    },
    launchDetailDgir(id) {
      this.$router.push({ path: "/decompte/dgir/detail/" + id })
    },
    launchModifPDgir(id, p) {
      this.$router.push({ path: "/decompte/" + p + "/dgir/modification/" + id })
    },
    getProfileDernierValideur(valideur) {
      if (valideur !== null) {
        return utils.getProfileDernierValideur(valideur)
      }
      return ""
    },
    getTypeDecompte(type) {
      return utils.getTypeDecompte(type)[1].toUpperCase()
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>
